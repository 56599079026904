import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import {
  Cushion,
  FlexContainer,
  Typography,
  MaxWidth,
  Rectangle,
  Shadow,
} from '@pitchero/react-ui';
import Icon from '../icon';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import { Trans } from '../../lib/i18n';
import ClubLink from '../club-link';

const CalendarTrainingSession = ({ club, session, noBorder }) => {
  const startTime = DateTime.fromISO(session.start).setZone(club.timezone);
  const endTime = DateTime.fromISO(session.end).setZone(club.timezone);

  return (
    <Cushion all="medium" style={{ textAlign: 'center' }}>
      {session.isCancelled && (
        <Cushion bottom="small">
          <Typography
            preset="tag--large"
            color="negative"
            component="div"
            align="right"
            responsive={[{ maxWidth: 'palm', props: { preset: 'tag--small' } }]}
          >
            <Trans i18nKey="common:training_cancelled">Cancelled</Trans>
          </Typography>
        </Cushion>
      )}
      <Rectangle borderBottom={noBorder ? '' : 'thinLightGrey'} component="div">
        <ClubLink currentClub={club} route="calendar.training" params={{ id: session.id }}>
          <div style={{ cursor: 'pointer' }}>
            <MaxWidth component="div" maxWidth={120} center>
              <Shadow>
                <Rectangle fill="black" radius="rounded">
                  <Cushion all="xsmall">
                    <FlexContainer alignItems="center" justifyContent="space-between">
                      <div>
                        <Typography
                          component="div"
                          preset="tag--small"
                          color="dustygrey"
                          align="left"
                        >
                          Training
                        </Typography>
                        <Typography component="div" preset="name" color="white" align="left">
                          {`${startTime.toFormat('HH:mm')} - ${endTime.toFormat('HH:mm')}`}
                        </Typography>
                      </div>
                      <Icon name="training" color="alto" size={23} />
                    </FlexContainer>
                  </Cushion>
                </Rectangle>
              </Shadow>
            </MaxWidth>
            <Cushion top="small">
              <MaxWidth component="div" maxWidth={315} center>
                <ClubLink currentClub={club} route="calendar.training" params={{ id: session.id }}>
                  <a>
                    <Typography preset="description" color="dustygrey">
                      {session.title}
                    </Typography>
                  </a>
                </ClubLink>
              </MaxWidth>
            </Cushion>
          </div>
        </ClubLink>
      </Rectangle>
    </Cushion>
  );
};

CalendarTrainingSession.defaultProps = {
  noBorder: false,
};

CalendarTrainingSession.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  session: PropTypes.shape().isRequired,
  noBorder: PropTypes.bool,
};

export default CalendarTrainingSession;
